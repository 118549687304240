<script>
// import { wpClient } from "@/services/wp/wp-client";
import avatarUploaderComp from "./process-cropperjs.vue";
// import dataURLtoBlob from "blueimp-canvas-to-blob";
import loadImage from "blueimp-load-image";
export default {
  props: {
    text: {
      default: "Cargar imágen",
      type: String,
    },
  },
  data() {
    return {
      accept: "image/jpeg, image/png",
      pickedFile: null,
    };
  },

  watch: {
    async pickedFile() {
      if (!this.pickedFile) return;
      await this.prepareImage(this.pickedFile);
      this.pickedFile = null;
    },
  },

  methods: {
    async prepareImage(file) {
      // console.log(file);
      const loader = this.$buefy.loading.open();
      let result = await new Promise(resolve => {
        loadImage(
          file,
          function(image) {
            if (image.type === "error") {
              resolve(null);
            } else {
              resolve(image.toDataURL(file.type, 1));
            }
          },
          {
            maxWidth: 2000,
            maxHeight: 2000,
            canvas: true,
            orientation: true,
          },
        );
      });
      // let img = new Image();
      // img.onload = () => {
      //   console.log(`width: `, img.width, `height: ${img.height}`);
      // };
      // img.src = result;
      // console.log(dataURLtoBlob(result));
      // console.log(result);
      // this.imgSrc = result;
      this.$sitio.modal.open({
        component: avatarUploaderComp,
        parent: this,
        props: {
          imgName: file.name,
          imgType: file.type,
          imgSrc: result,
        },
      });
      /**
       * 1. Pass the base64 to the cropper modal as a prop
       * 2. In the modal use this a the image src
       * 3. Process adjust the image
       * 4. Before upload, reprocess and resize if larger than needed
       */
      loader.close();
    },
  },
};
</script>

<template>
  <b-upload :accept="accept" v-model="pickedFile" :disabled="!!pickedFile">
    <a class="button is-primary" :disabled="!!pickedFile">
      <b-icon icon="upload"></b-icon>
      <span>{{ text }}</span>
    </a>
  </b-upload>
</template>

import { render, staticRenderFns } from "./process-cropperjs.vue?vue&type=template&id=2611f0a8&scoped=true&"
import script from "./process-cropperjs.vue?vue&type=script&lang=js&"
export * from "./process-cropperjs.vue?vue&type=script&lang=js&"
import style0 from "cropperjs/dist/cropper.css?vue&type=style&index=0&lang=css&"
import style1 from "./process-cropperjs.vue?vue&type=style&index=1&id=2611f0a8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2611f0a8",
  null
  
)

export default component.exports
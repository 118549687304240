<script>
import { wpService } from "@/services/wp";
import dataURLtoBlob from "blueimp-canvas-to-blob";
import loadImage from "blueimp-load-image";
import Cropper from "cropperjs";
// import "cropper.css";
export default {
  props: {
    imgName: String,
    imgType: String,
    imgSrc: String,
  },
  data() {
    return {
      // file: null,
      cropper: {},
      destination: {},
      image: {},
      cropped: null,
      cropping: {
        // maxWidth: 720,
        // maxHeight: 720,
        // width: 400,
        // height: 400,
        // minWidth: 256,
        // fillColor: "#fff",
        minHeight: 256,
      },
    };
  },

  mounted() {
    this.image = this.$refs.image;
    this.cropper = new Cropper(this.image, {
      viewMode: 1,
      toggleDragModeOnDblclick: false,
      cropBoxMovable: false,
      cropBoxResizable: false,
      autoCropArea: 1,
      // autoCrop: false,
      guides: false,
      // center: true,
      // responsive: false,
      // highlight: false,
      // modal: false,
      // background: false,
      scalable: false,
      // zoomable: false,
      wheelZoomRatio: 0.1,
      minContainerWidth: 200,
      minContainerHeight: 200,
      // minCropBoxWidth: 200,
      // minCropBoxHeight: 200,
      // minCanvasWidth: 400,
      // minCanvasHeight: 400,
      aspectRatio: 1,
      preview: ".author-chip .chip__image",
      // crop: () => {
      //   const canvas = this.cropper.getCroppedCanvas();
      //   this.destination = canvas.toDataURL("image/jpg");
      // },
      dragMode: "move",
    });
    // console.log(this.cropper);
  },

  methods: {
    getCroppedCanvas() {
      console.log(this.cropper.getCroppedCanvas(this.cropping));
    },
    async setCroppedCanvas() {
      let canvas = this.cropper.getCroppedCanvas(this.cropping);
      // console.log("canvas", canvas);
      let base64 = canvas.toDataURL(this.imgType);
      // console.log("base64", base64);
      // this.cropped = base64;
      let binary = dataURLtoBlob(base64);
      // console.log("binary", binary);
      let file = await this.processFile(binary);
      if (file) {
        const loader = this.$buefy.loading.open();
        await wpService.account
          .postAvatar(this.imgName, this.imgType, file)
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            this.$parent.close();
            loader.close();
          });
      }
    },
    async processFile(file, returnBlob = true) {
      // TODO: Check if browser supports next functions
      // let testing = true;
      // if (testing) return Promise.resolve(null);
      return new Promise(resolve => {
        let $this = this;
        loadImage(
          file,
          function(image) {
            if (image.type === "error") {
              $this.$buefy.toast.open({
                message: `Error al procesar imagen "${file.name}"`,
                type: "is-danger",
              });
              resolve(null);
            } else {
              let b64src = image.toDataURL(file.type, 1);
              let blob = dataURLtoBlob(b64src);
              if (blob.size >= 1000000) {
                b64src = image.toDataURL(file.type, 0.8);
              }
              resolve(returnBlob ? dataURLtoBlob(b64src) : b64src);
            }
          },
          {
            maxWidth: 800,
            maxHeight: 800,
            canvas: true, // return canvas instead of <img>
            orientation: true,
          },
        );
      });
    },
  },
};
</script>

<template>
  <div id="avatar-uploader">
    <h1 class="title modal-title">Ajusta tu foto</h1>
    <div class="img-container">
      <img ref="image" :src="imgSrc" crossorigin />
    </div>
    <div class="preview">
      <div class="label">Preview</div>
      <div class="author-chip">
        <div class="chip__image preview-container"></div>
        <div class="chip__data">
          <div class="title">Luis Rivera</div>
          <div><span>Tel. fijo: </span><a>(849) 547-3878</a></div>
          <div><span>Tel. móvil: </span><a>(829) 928-8226</a></div>
        </div>
      </div>
    </div>
    <hr />
    <div class="buttons">
      <button class="button" @click="getCroppedCanvas">getdata</button>
      <button class="button is-link" @click="setCroppedCanvas">Guardar</button>
    </div>
    <img v-if="cropped" :src="cropped" />
  </div>
</template>

<style src="cropperjs/dist/cropper.css"></style>

<style lang="scss" scoped>
#avatar-uploader {
  width: 100%;
  max-width: 30em;
}
.modal-title {
  margin-top: -1rem;
  font-weight: 900;
}
.preview {
  display: none;
  margin-top: 1.5rem;
  .label {
    font-size: 0.9375rem;
    font-weight: normal;
    opacity: 0.8;
    margin-bottom: 1rem;
  }
}
.author-chip {
  display: flex;
  // align-items: center;
  .chip__image {
    width: 100%;
    /* height: calc(372px * (9 / 16)); */
    width: 4.875rem;
    height: 4.875rem;
    overflow: hidden;
    border-radius: 0.5rem;
  }
  .chip__data {
    padding-top: 0.25rem;
    padding-left: 1rem;
    font-size: 0.875rem;
    .title {
      font-size: 1.25rem;
      font-weight: 500;
      margin-bottom: 0.125rem;
    }
  }
}
.buttons {
  justify-content: flex-end;
  // margin-top: 1.5rem;
  margin-bottom: -2rem;
}
.img-container {
  width: 100%;
  max-height: 20rem;
  border-radius: 0.25rem;
  overflow: hidden;
}
/deep/ .cropper-modal {
  opacity: 0.15;
}
/deep/ .cropper-canvas::after {
  content: "";
  display: block;
  position: absolute;
  background-color: #fff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.5;
}
/deep/ .cropper-view-box {
  outline: none;
  background-color: #fff;
}
/deep/ .cropper-face {
  border: 1px solid;
  border-image-slice: 1;
  border-image-repeat: repeat;
  border-image-source: url("data:image/gif;base64,R0lGODlhCgAKAJECAAAAAP///////wAAACH/C05FVFNDQVBFMi4wAwEAAAAh/wtYTVAgRGF0YVhNUDw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OEI5RDc5MTFDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OEI5RDc5MTBDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEBQoAAgAsAAAAAAoACgAAAhWEERkn7W3ei7KlagMWF/dKgYeyGAUAIfkEBQoAAgAsAAAAAAoACgAAAg+UYwLJ7RnQm7QmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYgLJHdiinNSAVfOEKoUCACH5BAUKAAIALAAAAAAKAAoAAAIRVISAdusPo3RAzYtjaMIaUQAAIfkEBQoAAgAsAAAAAAoACgAAAg+MDiem7Q8bSLFaG5il6xQAIfkEBQoAAgAsAAAAAAoACgAAAg+UYRLJ7QnQm7SmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYBLJDdiinNSEVfOEKoECACH5BAUKAAIALAAAAAAKAAoAAAIRFISBdusPo3RBzYsjaMIaUQAAOw==");
  background-color: transparent;
  opacity: 1;
}
</style>
